/* globals AfterPay */
import { asyncLoadFile, parseQueryString, extractQueryString, mergeQueryString } from '@shein/common-function'


const { NODE_SERVER_ENV } = gbCommonInfo

const noop = () => {}

export function debuggerLog (...args) {
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info('%cAfterPay-CashApp:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', ...args, '\n')
  } catch (e) {
    console.log(...args)
  }
}

class AfterPayCashApp {
  destroyCashAppPay = null
  beginCashAppPay = null
  mainEle = null
  static firstFail = 1 // 首次失败，状态更改0，不回调失败函数
  constructor() {
    this.createMainEle()
    this.restartCashAppPay()
    this.destroyCashAppPay = null
    this.beginCashAppPay = null
  }

  static initSDK () {
    if(window.AfterPay) return
    const isSandBox = ['debug', 'localhost'].includes(NODE_SERVER_ENV)
    return asyncLoadFile({
      label: 'script',
      attrs: {
        src: isSandBox ? 'https://portal.sandbox.afterpay.com/afterpay.js' : 'https://portal.afterpay.com/afterpay.js'
      }
    }).catch(err => {
      debuggerLog('fail afterpay.js: ', err)
    })
  }

  static replaceRoute (cbUrl) {
    if (!cbUrl) return
    if (history && history.replaceState) {
      history.replaceState(history.state, null, cbUrl)
    }
    window.location.href = cbUrl
  }

  static getCashAppPayListenerOptions ({ onCancel = noop, onFail = noop, onSuccess = null, onComplete = noop, requestOnFileGrant = false } = {
    onCancel: noop,
    onFail: noop,
    onSuccess: null,
    onComplete: noop,
    requestOnFileGrant: false,
  }) {
    return {
      requestOnFileGrant,
      onError () {
        AfterPayCashApp.firstFail && onFail({ msg: 'onError' })
        AfterPayCashApp.firstFail = 0
        debuggerLog('onError')
      },
      onComplete: function (event) {
        onComplete(event)
        debuggerLog(`onComplete|||: `, event.data)
        const { status, orderToken, callbackBaseUrl = '', grantId, id, cashtag, expiresAt, token } = event.data || {}
        if (requestOnFileGrant && !grantId || !requestOnFileGrant && (!callbackBaseUrl || !orderToken)) {
          AfterPayCashApp.firstFail && onFail({ msg: `onComplete：${callbackBaseUrl ? '' : 'callbackBaseUrl is empty'}${orderToken ? '' : ' orderToken is empty'}` })
          AfterPayCashApp.firstFail = 0
          return
        }
        const baseUrl = requestOnFileGrant ? location.href : callbackBaseUrl
        const redirectParams = parseQueryString(extractQueryString(baseUrl))
        // payCode及cbUrl fix afterpay渠道url不能太长，尽量缩短长度
        // eslint-disable-next-line no-unused-vars
        const { callbackUrl, paymentMethod, countryCode, payCode, cbUrl, ...channelParams } = redirectParams || {}

        const tokenParams = requestOnFileGrant && grantId ? {
          token,
          orderToken: orderToken || token,
          grantId,
          expiresAt: expiresAt instanceof Date ? expiresAt.toISOString() : expiresAt,
          cashTag: cashtag,
          customerId: id,
          status: 'SUCCESS'
        } : {}

        const redirectUrl = mergeQueryString({
          url: callbackUrl || cbUrl,
          mergeObj: { status, orderToken, ...tokenParams, ...channelParams }
        })
        // debuggerLog('redirectUrl----', channelParams, redirectUrl)
        if (!onSuccess && typeof onSuccess !== 'function') {
          AfterPayCashApp.replaceRoute(redirectUrl)
        } else {
          onSuccess(redirectUrl)
        }
      },
      /* Optional event listeners */
      eventListeners: {
        'CUSTOMER_INTERACTION': ({ isMobile }) => {
          debuggerLog(`CUSTOMER_INTERACTION is on Mobile: ${isMobile}`)
        },
        'CUSTOMER_REQUEST_DECLINED': () => {
          AfterPayCashApp.firstFail && onFail({ msg: 'CUSTOMER_REQUEST_DECLINED' })
          AfterPayCashApp.firstFail = 0
          debuggerLog(`CUSTOMER_REQUEST_DECLINED`)
        },
        'CUSTOMER_REQUEST_APPROVED': () => {
          debuggerLog(`CUSTOMER_REQUEST_APPROVED`)
        },
        'CUSTOMER_REQUEST_FAILED': () => {
          AfterPayCashApp.firstFail && onFail({ msg: 'CUSTOMER_REQUEST_FAILED' })
          AfterPayCashApp.firstFail = 0
          debuggerLog(`CUSTOMER_REQUEST_FAILED`)
        },
        'CUSTOMER_DISMISSED': () => {
          onCancel()
          debuggerLog(`CUSTOMER_DISMISSED`)
        }
      }
    }
  }

  createMainEle () {
    if (!document.querySelector('#cash-app-pay')) {
      const cashAppPay = document.createElement('div')
      cashAppPay.setAttribute('id', 'cash-app-pay')
      document.body.appendChild(cashAppPay)
    }
  }

  initializeForCashAppPay(options = {
    params: {
      token: '',
      countryCode: '',
      scene: '',
    },
    requestOnFileGrant: false,
    onFail: noop,
    onCancel: noop,
    onComplete: noop,
    onSuccess: null
  }) {
    var cashAppPayOptions = AfterPayCashApp.getCashAppPayListenerOptions(options)
    AfterPay.initializeForCashAppPay({
      countryCode: options?.params?.countryCode,
      token: options?.params?.token,
      cashAppPayOptions,
    })
    this.beginCashAppPay?.()
  }

  initializeCashAppPayListeners(options = {
    params: {
      countryCode: '',
      scene: ''
    },
    onCancel: noop,
    onFail: noop,
    onSuccess: null
  }) {
    const cashAppPayListenerOptions = AfterPayCashApp.getCashAppPayListenerOptions(options)
    try {
      window.AfterPay.initializeCashAppPayListeners({ countryCode: this.countryCode, cashAppPayListenerOptions })
    } catch (e) {
      options.onCancel()
    }
  }

  /**
   * 获取0元签约的options
   * @param {*} options.extraData.redirectUrl 重定向地址
   * @param {*} options.extraData.cashAppBrandId 品牌 ID
   * @param {*} onSuccess 成功回调
   * @param {*} onFail 失败回调
   * @param {*} onCancel 取消回调
   * @param {*} onInteraction 交互回调，埋点用
   * @returns 
   */
  getCashAppGrantOptions(options = { extraData: {}, onSuccess: noop, onFail: noop, onCancel: noop, onInteraction: noop, } ) {
    const {
      extraData,
      onFail,
      onSuccess,
      onCancel,
      onInteraction
    } = options
    const  cashAppPayListenerOptions = { 
      onComplete: ()=> {}, 
      eventListeners: {
        // 交互
        'CUSTOMER_INTERACTION': () => {
          onInteraction('afterpay-cashapp')
        },
        // 拒绝
        'CUSTOMER_REQUEST_DECLINED': () => {
          onCancel()
          this.restartCashAppPay()
          this.beginCashAppPay = null
        },
        // 成功
        'CUSTOMER_REQUEST_APPROVED': (data) => {
          const cashtag = data.customerProfile?.cashtag
          const id = data.customerProfile?.id
          let expiresAt = data.grants?.onFile?.expiresAt
          expiresAt = expiresAt instanceof Date ? expiresAt.toISOString() : expiresAt
          const grantId = data.grants?.onFile?.grantId
          onSuccess({
            cashtag,
            id,
            expiresAt,
            grantId,
          })
          this.restartCashAppPay()
          this.beginCashAppPay = null
        },
        // 失败
        'CUSTOMER_REQUEST_FAILED': () => {
          onFail()
          this.restartCashAppPay()
          this.beginCashAppPay = null
        }
      },
      ...extraData
    } 

    return cashAppPayListenerOptions
  }

  initializeForCashAppOnFileGrant( countryCode, options = {}) { 
    const cashAppPayOnFileOptions = this.getCashAppGrantOptions(options)
    AfterPay.initializeForCashAppOnFileGrant({ 
      countryCode, 
      cashAppPayOnFileOptions
    })
  
    this.beginCashAppPay?.()
  }

  initializeCashAppGrantListeners(countryCode, options = {}) {
    const cashAppPayListenerOptions = this.getCashAppGrantOptions(options)
    try {
      window.AfterPay.initializeCashAppPayListeners({ countryCode, cashAppPayListenerOptions })
    } catch (e) {
      //
    }
  }

  renderCashAppPay(options = {
    countryCode: ''
  }) {
    return new Promise((resolve, reject) => {
      if (this.beginCashAppPay) {
        resolve()
        return
      }
      // console.time('renderCashAppPayButton')
      const self = this
      const cashAppPayButtonOptions = {
        button: false,
        manage: false,
        onBegin: function ({ begin, destroy }) {
          // console.timeEnd('renderCashAppPayButton')
          self.beginCashAppPay = begin // store begin for subsequent calls
          self.destroyCashAppPay = destroy
          resolve()
          debuggerLog('onBegin----')
        }
      }
      //This allows you to render the button without a token
      AfterPay.renderCashAppPayButton({
        countryCode: options?.countryCode,
        cashAppPayButtonOptions
      })
      // 兜底处理
      setTimeout(() => {
        if (!self.beginCashAppPay) reject()
      }, 10000)
    })
  }

  restartCashAppPay () {
    AfterPay?.restartCashAppPay?.()
    this.destroyCashAppPay?.()
  }
}

export default AfterPayCashApp
